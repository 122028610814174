import * as React from "react"
import StyledLink from "../components/Link"
import Text from "../components/Text"

const NotFoundPage = () => {
    return (
        <>
            <Text size="heading-05">Whoops... that's not here anymore</Text>
        </>
    )
}

export default NotFoundPage
